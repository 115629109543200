<nav
  class="navbar col-12 navbar-scrolled navbar-expand-lg fixed-top bg-body-tertiary"
>
  <div>
    <a
      class="navbar-brand nav-bar-title container d-flex justify-content-start"
      href="#"
    >
      <img
        src="/assets/image/logo.webp"
        alt="Logo of the compagny of Louison Haas"
        width="70"
        height="auto"
        class="me-2"
      />
      <div
        class="align-self-center navbar-collapse ms-2 little-texte text-wrap"
      >
        Louison - Masseuse Énergéticienne
      </div>
    </a>
  </div>
  <button
    class="navbar-toggler me-4"
    type="button"
    data-bs-toggle="offcanvas"
    data-bs-target="#offcanvasNavbar"
    aria-controls="offcanvasNavbar"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasNavbar"
    aria-labelledby="offcanvasNavbarLabel"
  >
    <div class="offcanvas-header green-light-transparent">
      <h5 class="offcanvas-title" id="offcanvasNavbarLabel"></h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
        <li class="nav-item">
          <a class="nav-link" href="#">Accueil</a>
        </li>
        <ul class="navbar-nav nav-pills me-2">
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="/presentation">
              Qui suis je ?
            </a>
          </li>
        </ul>
        <ul class="nav-item dropdown navbar-nav nav-pills me-2">
          <a
            class="nav-link dropdown-toggle"
            aria-current="page"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Boutique
          </a>
          <ul class="dropdown-menu green-light-transparent">
            <li><a class="dropdown-item" href="/bracelets">Bracelets</a></li>
            <li><a class="dropdown-item" href="/huiles">Huiles</a></li>
            <li><a class="dropdown-item" href="/bougies">Bougies</a></li>
          </ul>
        </ul>
        <ul class="nav-item dropdown navbar-nav nav-pills me-2">
          <a
            class="nav-link dropdown-toggle"
            aria-current="page"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Prestations
          </a>
          <ul class="dropdown-menu green-light-transparent">
            <li><a class="dropdown-item" href="/massages">Massages</a></li>
            <li><a class="dropdown-item" href="/soin">Soin</a></li>
            <li><a class="dropdown-item" href="/programmes">Programmes</a></li>
            <li><a class="dropdown-item" href="/forfait">Forfaits</a></li>
          </ul>
        </ul>
        <li class="nav-item">
          <a class="nav-link" href="/carte-cadeau">Carte Cadeaux</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/contact">Contact</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="top"></div>
