import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { VisitService } from '../../core/adapters/visited.getaway';

@Injectable({
  providedIn: 'root',
})
export class LocalService {
  private visitService = inject(VisitService);

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  public countVisited() {
    if (isPlatformBrowser(this.platformId)) {
      if (sessionStorage.getItem('visited') === null) {
        sessionStorage.setItem('visited', '1');
        this.visitService.pushVisit('visited', 1);
      } else {
        let visits = Number(sessionStorage.getItem('pagevisited'));
        sessionStorage.setItem('pagevisited', String(++visits));
        this.visitService.pushVisit('pagevisited', 1);
      }
    }
  }
}
