<nav class="navbar bg-body-tertiary mt-5 p-0">
  <div class="container pt-3">
    <div class="col-12 col-md-4">
      <div class="row">
        <h3 class="text-white">
          <strong class="text-center"><em>Contact</em></strong>
        </h3>

        <a href="mailto:louison.haas@hotmail.com" class="d-flex"
          ><img
            class="logo-social-media"
            src="/assets/icon/email.svg"
            alt="email logo with link to the page"
          />
          <p class="my-auto ms-2">Louison.haas&#64;hotmail.com</p></a
        >
        <a href="tel:0622690337" class="d-flex"
          ><img
            class="logo-social-media"
            src="/assets/icon/phone.svg"
            alt="phone logo with link to the page"
          />
          <p class="my-auto ms-2">06.22.69.03.37</p></a
        >
        <a href="https://www.facebook.com/julie.hernadez.777" class="d-flex">
          <img
            class="logo-social-media"
            src="/assets/icon/facebook.svg"
            alt="Facebook logo with link to the page"
          />
          <p class="my-auto ms-2">lou.masseuse.énergéticienne</p>
        </a>
        <a
          href="https://www.instagram.com/lou.masseuse.energeticienne_/?next=%2Fp%2FCiqrAYirswQ%2F&ref=Mini%20Instagram"
          class="d-flex"
        >
          <img
            class="logo-social-media"
            src="/assets/icon/instagram.svg"
            alt="Instagram logo with link to the page"
          />
          <p class="my-auto ms-2">lou.masseuse.énergéticienne</p>
        </a>
      </div>
      <div class="d-flex justify-content-center">
        <button
          type="button"
          class="btn green text-white mt-3 col-8"
          routerLink="/contact"
        >
          Me contacter
        </button>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <h3 class="text-white">
        <strong class="text-center"><em>Massage en Salon</em></strong>
      </h3>
      <div class="d-flex ms-3">
        <img
          class="logo-social-media"
          src="/assets/icon/salon.svg"
          alt="Salon logo "
        />
        <p class="my-auto ms-2">46 Rue Bigarena, 64700 Hendaye</p>
      </div>

      <h3 class="text-white mt-2">
        <strong class="text-center"><em>Massage a domicile</em></strong>
      </h3>
      <div class="d-flex ms-3">
        <img
          class="logo-social-media"
          src="/assets/icon/domicile.svg"
          alt="Domicile logo "
        />
        <p class="my-auto ms-2">
          Massage à domicile ou sur le lieu de votre choix sur toute la côte
          basque et Landaise, dans les villes de Hendaye, Ascain, Urrugne, Saint
          Jean de Luz, Guéthary, Arbonne, Ahetze, Arcangues, Anglet, Biarritz,
          Bidart, et bien d'autres...
        </p>
      </div>
    </div>
  </div>
  <div class="container justify-content-center">
    <div routerLink="/mentions-legales" class="btn">
      <p class="text-white text-center my-2">
        © 2021 Louison Haas - Masseuse - Tous droits réservés - Mentions Légales
      </p>
    </div>
  </div>
</nav>
