import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', redirectTo: 'accueil', pathMatch: 'full' },
  {
    path: 'massages',
    loadComponent: () =>
      import('./views/prestations/massages/massages.component'),
    data: {
      meta: {
        title: 'Nos Massages | Louison Masseuse - Détente et Relaxation',
        description:
          'Explorez nos différents massages offerts chez Louison Masseuse, conçus pour détendre votre corps et apaiser votre esprit. Trouvez le massage qui vous convient.',
      },
    },
  },
  {
    path: 'massages/:id',
    loadComponent: () =>
      import(
        './views/prestations/massages/massage-details/massage-details.component'
      ),
    data: {
      meta: {
        title: 'Massage - Détente Profonde | Louison Masseuse',
        description:
          "Profitez d'un massage spécialisé pour un relâchement total du corps et de l'esprit. Techniques douces et enveloppantes pour une relaxation maximale.",
      },
    },
  },
  {
    path: 'accueil',
    loadComponent: () => import('./views/accueil/accueil.component'),
    data: {
      meta: {
        title: 'Accueil | Louison Masseuse',
        description:
          'Découvrez les services de massage offerts par Louison, votre masseuse professionnelle pour un moment de détente et de bien-être.',
      },
    },
  },
  {
    path: 'presentation',
    loadComponent: () => import('./views/who-am-i/who-am-i.component'),
    data: {
      meta: {
        title: 'À propos de Louison Masseuse - Votre Expert en Massages',
        description:
          'Rencontrez Louison, masseuse professionnelle, et découvrez sa passion pour le bien-être. Engagée à offrir des expériences de massage uniques et personnalisées.',
      },
    },
  },
  {
    path: 'horaires',
    loadComponent: () =>
      import('./views/schedules/schedules.component').then(
        (module) => module.ScheduleComponent
      ),
  },
  {
    path: 'soin',
    loadComponent: () => import('./views/prestations/care/care.component'),
    data: {
      meta: {
        title: 'Nos Soins | Louison Masseuse',
        description:
          'Explorez nos différents soins offerts chez Louison Masseuse, conçus pour détendre votre corps et apaiser votre esprit. Trouvez le soin qui vous convient.',
      },
    },
  },
  {
    path: 'soins/:id',
    loadComponent: () =>
      import('./views/prestations/care/care-details/care-details.component'),
    data: {
      meta: {
        title: 'Soin | Louison Masseuse',
        description:
          'Offrez-vous un soin pour retrouver éclat et vitalité. Des produits naturels et des techniques douces pour chouchouter votre peau.',
      },
    },
  },
  {
    path: 'forfait',
    loadComponent: () =>
      import('./views/prestations/package/package.component'),
    data: {
      meta: {
        title: 'Forfaits Massage - Plus de Bien-être | Louison Masseuse',
        description:
          'Profitez de nos forfaits massages pour une détente prolongée. Des offres avantageuses pour intégrer le massage dans votre routine de bien-être.',
      },
    },
  },
  {
    path: 'contact',
    loadComponent: () => import('./views/contact/contact.component'),
    data: {
      meta: {
        title: 'Contactez Louison Masseuse - Réservez Votre Massage',
        description:
          'Prêt pour une expérience de massage inoubliable ? Contactez Louison Masseuse pour réserver votre séance ou pour toute question.',
      },
    },
  },
  {
    path: 'programmes',
    loadComponent: () =>
      import('./views/prestations/programs/programs.component'),
    data: {
      meta: {
        title: 'Programmes de Bien-être sur Mesure | Louison Masseuse',
        description:
          'Découvrez nos programmes de bien-être conçus pour répondre à vos objectifs personnels. Une approche holistique pour harmoniser corps et esprit.',
      },
    },
  },
  {
    path: 'bracelets',
    loadComponent: () => import('./views/shop/bracelet/bracelet.component'),
    data: {
      meta: {
        title:
          'Bracelets Énergétiques - Louison Masseuse | Harmonie et Équilibre',
        description:
          'Explorez notre collection de bracelets énergétiques chez Louison Masseuse. Chaque pièce est conçue pour apporter harmonie et équilibre, complétant parfaitement votre routine de bien-être.',
      },
    },
  },
  {
    path: 'huiles',
    loadComponent: () => import('./views/shop/oils/oil.component'),
    data: {
      meta: {
        title: 'Nos Huiles de Massage - Qualité Supérieure | Louison Masseuse',
        description:
          "Découvrez notre sélection d'huiles de massage naturelles et bio, soigneusement choisies pour leurs propriétés relaxantes et bénéfiques pour la peau.",
      },
    },
  },
  {
    path: 'bougies',
    loadComponent: () => import('./views/shop/candles/candles.component'),
    data: {
      meta: {
        title:
          'Bougies Aromatiques - Créez une Ambiance Relaxante | Louison Masseuse',
        description:
          'Nos bougies aromatiques chez Louison Masseuse sont parfaites pour créer une atmosphère relaxante et apaisante chez vous. Découvrez nos fragrances inspirées par la nature et le bien-être.',
      },
    },
  },
  {
    path: 'mentions-legales',
    loadComponent: () =>
      import('./views/legal-mentions/legal-mentions.component'),
    data: {
      meta: {
        title: 'Mentions Légales - Louison Masseuse',
        description:
          'Consultez les mentions légales de notre site. Engagement envers la transparence et le respect de la confidentialité.',
      },
    },
  },
  {
    path: 'carte-cadeau',
    loadComponent: () => import('./views/gift-card/gift-card.component'),
    data: {
      meta: {
        title:
          'Offrez le Cadeau du Bien-être avec nos Cartes Cadeaux | Louison Masseuse',
        description:
          'Surprenez vos proches avec une carte cadeau Louison Masseuse. Un cadeau idéal pour partager un moment de détente et de soin.',
      },
    },
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./views/admin/admin.routes').then((m) => m.adminRoutes),
  },
  {
    path: '**',
    loadComponent: () => import('./views/not-found/not-found.component'),
  },
];
